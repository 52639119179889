import React from 'react';
import Styleswrapper from '../components/StylesWrapper';
import PageMeta from '../components/blocks/helmet/PageMeta';
import Mainheader from '../components/MainHeader';
import { motion } from 'framer-motion';

import '../styles/colophon.css';

const Colophon = () => {
    return (
        <Styleswrapper
            bgColor1="#081D4D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="20%"
            noise="true"
        >
            <PageMeta
                theme="#081D4D"
                title="Colophon — Nihar Bhagat"
                description=""
                image="../images/social/contact/default.jpg"
                twitterImage="../images/social/contact/twitter.jpg"
            />
            <Mainheader
                bgColor1="#081D4D"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="20%"
                noise="true"
            />

            <section className="colophon-main">
                <div className="colophon-main-wrapper">
                    <motion.div
                        className="main-copy"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, ease: "circOut" }}
                    >
                        <h1>Colophon</h1>
                        <p>
                            This portfolio website is designed to showcase my mastery over digital interface design and presentation. I have been honest with all the copy and imagery used throughout.
                        </p>

                        <p>
                            Original wireframing and concept were done using pencil and paper and I used Adobe XD to give it a digital form. The beautiful iconography is courtesy of Windows 11 emojis, animations by GSAP and Framer Motion. The colors are inspired from this Kangra painting and gradients of sunsets.
                        </p>

                        <p>
                            This site is brought to life by the talented hands of Akshar
                            Dave who coded it on VSCode using ReactJS and Gatsby. It
                            is one of the cleanest codes you will ever see.
                            Feel free to inspect.
                        </p>

                        <p>
                            Technically, it wouldn't have been possible to show all of this to you if it hadn't been for Netlify's generous hosting and robust deployment tools. This project deepened my gratitude for the open source community's contributions to the internet.
                        </p>
                    </motion.div>
                </div>
            </section>
        </Styleswrapper>
    );
}

export default Colophon;
